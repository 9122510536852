import { graphqlFetch } from '@api/graphql/gql-fetch';
import { searchWithBoundingBoxQuery } from '@api/graphql/searchWithBoundingBox';
import type { SearchResponseData } from '@typings/api';
import type { Location } from '@typings/redux/location';
import type { BoundingBox } from '@typings/search';
import { generateSeed } from '@utils/generateSeed';
import sortParam from '@utils/sortParam';

interface GetBoundingBoxParams {
	map: google.maps.Map | null;
}

interface ClearFiltersSearchParams {
	categoryGuid: string;
	location: Location;
	filters: string[];
	map: google.maps.Map | null;
	page: number;
}

const getBoundingBox = ({ map }: GetBoundingBoxParams): BoundingBox | null => {
	if (!map) {
		return null;
	}

	const bounds = map.getBounds();

	if (!bounds) {
		return null;
	}

	const northEast = bounds.getNorthEast();
	const southWest = bounds.getSouthWest();

	const topLeft = {
		latitude: northEast.lat(),
		longitude: southWest.lng(),
	};

	const bottomRight = {
		latitude: southWest.lat(),
		longitude: northEast.lng(),
	};

	return { topLeft, bottomRight };
};

const clearFiltersSearch = async ({
	categoryGuid,
	filters,
	location,
	map,
	page,
}: ClearFiltersSearchParams) => {
	const boundingBox = getBoundingBox({ map });

	const searchOptions = {
		categoryGuid,
		city: location.city,
		filters,
		page,
		sort: sortParam('featured'),
		state: location.stateCode,
		seed: generateSeed(),
	};

	const data = {
		query: searchWithBoundingBoxQuery(),
		variables: {
			...searchOptions,
			boundingBox,
		},
	};

	return graphqlFetch<SearchResponseData>(data);
};

export { clearFiltersSearch, getBoundingBox };
