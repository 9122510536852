import { personalizedSortFragment } from './searchFragment';

export const searchWithBoundingBoxQuery = () => {
	return `
    query(
      $boundingBox: GeoBoundingBoxInput,
      $categoryGuid: String!,
      $city: String,
      $filters: [String],
      $fixedPageSize: Boolean,
      $limit: Int,
      $page: Int,
      $search_fallback_offset: Int,
      $seed: String,
      $sort: [SortInput!],
      $state: String,
    ){
      search: personalizedSearch (
        boundingBox: $boundingBox,
        filters: $filters,
        fixedPageSize: $fixedPageSize,
        limit: $limit,
        location: { city: $city, state: $state },
        page: $page,
        seed: $seed,
        sort: $sort
      ) {
        boundingBox {
          topLeft {
            latitude
            longitude
          }
          bottomRight {
            latitude
            longitude
          }
        }
        marketCode
        pagination {
          limit
          count
          page
        }
        profiles {
          ...PersonalizedSortProfileProperties
        }
        total {
          profiles
        }
        supplemental {
          ...PersonalizedSortProfileProperties
        }
        sort {
          type
          order
          variation
          version
          scoreType
        }
      }
      searchFallback(
        filters: $filters,
        location: { city: $city, state: $state},
        offset: $search_fallback_offset,
        limit: $limit,
        sort: $sort,
        seed: $seed,
      ) {
        citySearch
        stateSearch
        categorySearch
        home
      }
      isPermitted(
        location: { city: $city, state: $state },
        categoryGuid: $categoryGuid
      ) {
        flag
      }
      searchCopy(
        categoryGuid: $categoryGuid,
        location: { city: $city, state: $state},
      ) {
        html
      }
    }
    ${personalizedSortFragment}
  `;
};
