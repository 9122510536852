export default `
  query(
    $city: String,
    $filters: [String],
    $seed: String
    $state: String,
  ){
    search(
      filters: $filters,
      location: { city: $city, state: $state },
      seed: $seed
    ) {
      total {
        profiles
        supplemental
      }
    }
  }
`;
