import axios from '@axios';
import { generateSeed } from '../utils/generateSeed';
import searchCountQuery from './graphql/searchCount';

export interface SearchArgs {
	city: string;
	state: string;
	filters: string[];
}

const searchCount = async (args: SearchArgs): Promise<number> => {
	const { city, state, filters } = args;

	const data = {
		query: searchCountQuery,
		variables: {
			city,
			filters,
			seed: generateSeed(),
			state,
		},
	};

	const response: API.SearchCountResponse = await axios.post(data);
	return response?.data?.data?.search?.total?.profiles;
};

export default searchCount;
